<template>
  <fb-button
    :label="label"
    :icon="icon"
    :variant="variant"
    :icon-only="iconOnly"
    :loading="loading"
    :tool-tip-text="toolTipText"
    loading-text="Uploading..."
    @onClick="upload"
  />
</template>

<script>
import FbButton from '@/layouts/components/HwaButton.vue'

export default {
  name: 'HwaFileButton',
  components: { FbButton },
  props: {
    icon: {
      type: String,
      default: 'UploadCloudIcon',
    },
    label: {
      type: String,
      default: 'Upload',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    iconOnly: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: 'primary',
    },
    accept: {
      type: String,
      default: 'image/jpeg, image/png, image/jpg',
    },
    toolTipText: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      selectedFiles: [],
    }
  },
  methods: {
    upload() {
      const el = document.createElement('input')
      el.type = 'file'
      if (this.multiple) {
        el.multiple = true
      }
      el.accept = this.accept
      el.onchange = event => {
        const { files } = event.target
        if (this.multiple) {
          this.selectedFiles = [...this.selectedFiles, ...files]
          this.$emit('onFileUpload', this.selectedFiles)
        } else {
          const reader = new FileReader()
          reader.readAsDataURL(files[0])
          reader.onload = () => {
            this.$emit('onFileUpload', {
              file: files[0],
              dataUrl: reader.result,
            })
          }
        }
      }
      el.click()
    },
  },
}
</script>
<style scoped>

</style>
