<template>
  <validation-provider
    #default="{ errors }"
    :vid="name"
    :name="formatName(name)"
    :rules="rules"
  >
    <b-form-group>
      <label
        v-if="label"
        :for="name"
      >{{ label }}</label>
      <b-form-textarea
        :placeholder="placeholder"
        :rows="rows"
        no-resize
        :value="value"
        @input="inputChanged"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </b-form-group>
  </validation-provider>
</template>

<script>
import {
  BFormGroup, BFormTextarea,
} from 'bootstrap-vue'
/* eslint-disable global-require */
import { ValidationProvider } from 'vee-validate'
import { required, email } from '@core/utils/validations/validations'

export default {
  name: 'HwaTextArea',
  components: {
    BFormGroup,
    BFormTextarea,
    ValidationProvider,
  },
  props: {
    immediate:{
      type: Boolean,
      default: true,
    },
    name: {
      type: String,
      default: 'input',
    },
    label: {
      type: String,
      default: null,
    },
    rows: {
      type: Number,
      default: 3,
    },
    placeholder: {
      type: String,
      default: null,
    },
    value: {
      type: String,
      default: '',
    },
    rules: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      required,
      email,
    }
  },
  methods: {
    formatName(name) {
      return name.replaceAll('_', ' ')
    },
    suffixIconClicked(e) {
      this.$emit('onSuffixIconClick', e)
    },
    prefixIconClicked(e) {
      this.$emit('onPrefixIconClick', e)
    },
    enterPressed(e) {
      this.$emit('onEnterPressed', e)
    },
    inputChanged(e) {
      this.$emit('input', e)
    },
  },
}
</script>

<style scoped>
  textarea {
    color:black;
  }
</style>
