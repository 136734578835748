<template>

  <b-card>
   <div class="row">
    <div :class="image?'col-md-8':'col-md-12'">
      <div class="row">
      <div class="col-12">
        <hwa-input
          v-model="about.title"
          label="Title"
        />
      </div>
      <!-- <div class="col-12 mt-2">
        <label for="">Content</label>
      </div> -->
      <div class="col-12">
        <hwa-text-area
        label="Description"
          v-model="about.description"
        />
      </div>
      <div class="col-12">
        <hwa-text-area
        label="Our Mission"
          v-model="about.mission"
        />
      </div>
      <div class="col-12">
        <hwa-text-area
        label="Our Vission"
          v-model="about.vision"
        />
      </div>
      <div class="col-12">
        <hwa-text-area
          label="Our Goal"
          v-model="about.goal"
        />
      </div>
      <div class="col-12">
          <hwa-file-button
            class="mr-2"
            @onFileUpload="handleFileUpload"
          />
      </div>
    </div>
    </div>
    <div v-if="image" class="col-md-4" style="display:flex;justify-content:center;align-items:center;">
      <b-avatar
            v-if="image"
            :src="image"
            size="200"
          />
    </div>
    <div class="col-12 mt-2">
        <hwa-button
          :loading="loading"
          class="float-right"
          label="Update"
          @onClick="updateAbout()"
        />
      </div>
   </div>
  </b-card>
</template>

<script>
// eslint-disable-next-line
  import 'quill/dist/quill.core.css'
// eslint-disable-next-line
  import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
  import 'quill/dist/quill.bubble.css'

import HwaInput from '@/layouts/components/HwaInput.vue'
import { BCard, BAvatar } from 'bootstrap-vue'
import showToast from '@/mixins/showToast'
import HwaButton from '@/layouts/components/HwaButton.vue'
import HwaTextArea from '@/layouts/components/HwaTextArea.vue'
import HwaFileButton from '@/layouts/components/HwaFileButton.vue'

export default {
  name: 'AboutContent',
  components: {
    HwaFileButton,
    HwaTextArea,
    HwaButton,
    HwaInput,
    BCard,
    BAvatar,
  },
  mixins: [showToast],
  data: () => ({
    about: {
      id: '',
      title: '',
      description: '',
      mission: '',
      vision: '',
      goal: '',
      about_img: null,
    },
    loading: false,
    image: null,
  }),
  computed: {
    aboutContent() {
      return this.$store.getters['auth/aboutContent']
    },
  },
  watch: {
    aboutContent(val) {
      this.about = val
      if (this.about.about_img) {
        this.image = `${process.env.VUE_APP_STORAGE_PATH_MORTGAGE}/${this.about.about_img}`
      }
    },
  },
  created() {
    this.about = this.aboutContent
    if (this.about.about_img) {
      this.image = `${process.env.VUE_APP_STORAGE_PATH_MORTGAGE}/${this.about.about_img}`
    }
    this.getAboutContent()
  },
  methods: {
    handleFileUpload(file) {
      this.about.about_img = file.file
      this.image = file.dataUrl
    },
    updateAbout() {
      this.loading = true
      const fd = new FormData()
      fd.append('title', this.about.title)
      fd.append('description', this.about.description)
      fd.append('mission', this.about.mission)
      fd.append('vision', this.about.vision)
      fd.append('goal', this.about.goal)
      fd.append('about_img', this.about.about_img)

      this.$http.post(`api/abouts/${this.about.id}`, fd)
        .then(res => {
          this.$store.commit('auth/setAboutContent', res.data)
          this.showSuccess('Updated Successfully')
          this.loading = false
        }).catch(err => {
          this.loading = false
          this.showError('Failed to update')
        })
    },
    getAboutContent() {
      this.$http.get('api/abouts')
        .then(res => {
          this.$store.commit('auth/setAboutContent', res.data[0])
          this.loaded = true
        })
    },
  },
}
</script>
